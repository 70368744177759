// must be included in file
/*
<style>
    .blue{
        color:blue;
    }
    .green{
        color:green;
    }
    .orange{
        color:orange;
    }
    .red{
        color:red;
    }
</style>
*/
export const getFileIconClass = (extension) => {
    if (!extension){
        return ""
    }
    switch (extension?.toLowerCase()) {
        case "pdf":
            return 'bi bi-file-earmark-pdf red';
        case "html":
            return 'bi bi-file-earmark-code';
        case "doc":
        case "docx":
            return 'bi bi-file-earmark-word blue'
        case "txt":
            return 'bi bi-file-earmark-text blue'
        case "msg":
        case "rtf":
            return 'bi bi-file-earmark-richtext blue'
        case "xls":
        case "xlsm":
        case "xlsx":
            return 'bi bi-file-earmark-excel green'
        case "ppt":
        case "pptx":
            return 'bi bi-file-earmark-ppt orange'
        case "jpg":
        case "jpeg":
        case "bmp":
        case "png":
        case "gif":
            return 'bi bi-file-earmark-image orange'
        case 'mp4':
        case 'avi':
        case 'vmw':
        case 'mpg':
        case 'mov':
            return 'bi bi-file-earmark-play blue'
        case "zip":
        case "rar":
            return 'bi bi-file-earmark-zip red'
    }

    return 'bi bi-file-earmark'
}